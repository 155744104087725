import React from "react";
import theme from "theme";
import { Theme, Text, Section, Structure, Box, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				PeakFitness
			</title>
			<meta name={"description"} content={"Наш заклад може похвалитися широким вибором обладнання, різноманітними заняттями та персоналізованими фітнес-програмами, які створені для досягнення ваших цілей щодо фізичного та психічного здоров’я."} />
			<meta property={"og:title"} content={"PeakFitness"} />
			<meta property={"og:description"} content={"Наш заклад може похвалитися широким вибором обладнання, різноманітними заняттями та персоналізованими фітнес-програмами, які створені для досягнення ваших цілей щодо фізичного та психічного здоров’я."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/666c07b88d4a0c0020306e7c/images/1-3.jpg?v=2024-06-14T11:56:03.728Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/666c07b88d4a0c0020306e7c/images/1-3.jpg?v=2024-06-14T11:56:03.728Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/666c07b88d4a0c0020306e7c/images/1-3.jpg?v=2024-06-14T11:56:03.728Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/666c07b88d4a0c0020306e7c/images/1-3.jpg?v=2024-06-14T11:56:03.728Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/666c07b88d4a0c0020306e7c/images/1-3.jpg?v=2024-06-14T11:56:03.728Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/666c07b88d4a0c0020306e7c/images/1-3.jpg?v=2024-06-14T11:56:03.728Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/666c07b88d4a0c0020306e7c/images/1-3.jpg?v=2024-06-14T11:56:03.728Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header>
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/666c07b88d4a0c0020306e7c/images/2-2.jpg?v=2024-06-14T11:56:03.686Z) top/cover scroll"
			padding="120px 0 120px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Stack>
				<StackItem width="75%" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text as="h1" font="--headline2" md-font="--headline2" margin="10px 0">
						Наш тренажерний зал, розроблений для будь-якого рівня фізичної підготовки, призначений для особистісного розвитку та гарного самопочуття в привабливому та сприятливому середовищі.
					</Text>
				</StackItem>
			</Stack>
		</Section>
		<Structure cells-number-total="3" cells-number-group="3">
			<Override slot="Content" grid-template-columns="9fr 3fr" md-grid-template-columns="repeat(6, 2fr)" sm-grid-template-columns="12fr">
				<Override
					slot="Cell 0th"
					grid-column="1 / auto"
					grid-row="auto / span 2"
					md-grid-column="1 / span 6"
					md-grid-row="span"
					sm-grid-column="auto"
					sm-grid-row="span"
				/>
				<Override slot="Cell 1st" md-grid-column="1 / span 3" sm-grid-column="auto" />
				<Override slot="Cell 2nd" md-grid-column="4 / span 3" sm-grid-column="auto" />
				<Override slot="cell-0">
					<Text
						margin="0px 0px 24px 0px"
						color="#ffffff"
						font="--headline2"
						sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
						text-transform="uppercase"
					>
						Чому PeakFitness?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="--headline4"
						color="--light"
						text-align="left"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
						md-margin="0px 0px 32px 0px"
						max-width="70%"
					>
						Від йоги та пілатесу до високоінтенсивних інтервальних тренувань і силових тренувань, наш широкий вибір занять задовольняє будь-які фітнес-уподобання.
						<br />
						<br />
						<br />
						Ми віримо в силу спільноти сприяти мотивації та підтримці. Наш спортзал – це місце, де зав’язуються дружні стосунки та разом досягаються цілі.
					</Text>
				</Override>
				<Override slot="cell-1">
					<Text
						margin="32px 0px 16px 0px"
						font="--lead"
						color="--green"
						lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
						md-margin="12px 0px 16px 0px"
						sm-margin="24px 0px 16px 0px"
					>
						Наш тренажерний зал оснащений передовими фітнес-технологіями та добре доглянутим обладнанням, щоб покращити ваші враження від тренувань.
					</Text>
				</Override>
				<Override slot="cell-2">
					<Text
						sm-margin="24px 0px 16px 0px"
						margin="32px 0px 16px 0px"
						font="--lead"
						color="--green"
						lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
						md-margin="12px 0px 16px 0px"
					>
						Сертифіковані та досвідчені тренери завжди готові надати професійні поради, персоналізовані плани навчання та мотиваційну підтримку.
					</Text>
				</Override>
				{"                            "}
			</Override>
		</Structure>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				width="100%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					font="--headline2"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
					text-transform="uppercase"
				>
					Додаткові можливості
				</Text>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="40px 0px 0px 0px"
					color="--light"
					font="--lead"
				>
					Оздоровчі програми: Ми пропонуємо комплексні оздоровчі програми, які включають поради щодо харчування, семінари з психічного здоров’я та навчання способу життя.{"\n"}
					<br />
					Місце, зручне для дітей: Для зайнятих батьків ми пропонуємо безпечну та веселу зону для дітей, щоб ви могли зосередитися на тренуванні, знаючи, що ваші діти розважаються та піклуються про них.{"\n"}
					<br />
					Доступ 24/7: Насолоджуйтесь зручностями тренажерного залу, який відповідає вашому графіку.{" "}
					<br />
					Наші заклади відкриті цілодобово, тому ви можете легко тренуватися, коли знайдете час.
Індивідуалізовані зони відновлення:{" "}
					<br />
					{" "}Спеціальні зони, призначені для відновлення після тренування, з використанням найновіших технологій відновлення та обладнання.
				</Text>
			</Box>
		</Section>
		<Section
			padding="140px 0 140px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/666c07b88d4a0c0020306e7c/images/2-3%20%281%29.jpg?v=2024-06-14T11:56:03.695Z) center center/cover no-repeat"
			min-height="40vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline3"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Приєднайтеся до PeakFitness сьогодні
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Не чекайте, щоб розпочати свою фітнес-мандрівку. Відвідайте PeakFitness і побачите різницю на власні очі. Приєднуйтесь до нашої спільноти сьогодні та почніть сходження до нових висот. Ми раді вітати вас у PeakFitness – де починається ваша фітнес-мандрівка.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Контакти
				</Link>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text">
				Ми раді вітати вас у PeakFitness – де починається ваша фітнес-мандрівка.
			</Override>
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="box2" />
			<Override slot="box1" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="box3" />
			<Override slot="box" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});